@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300");

body {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1em;
  font-weight: 600;
}
a {
  text-decoration: none;
  color: black;
}
.text-align-center {
  text-align: center;
}

/* Navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
}
.nav-brand {
  font-size: 2em;
  font-weight: 800;
}
.nav-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav-list > ul {
  display: flex;
  list-style: none;
}
.nav-list > ul > li {
  margin: 1.2em;
}
.GitHub-icon {
  margin: 0 0 0 1em;
}

/* Input Form */
.form-div,
.input-form {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-form {
  flex-direction: column;
}
.input-form > label {
  position: relative;
}
.input-span {
  position: absolute;
  font-size: 1.2em;
  left: 2.6em;
  top: 1.9em;
  transition: 0.25s ease-in all;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
}
.input-field,
.submit-btn {
  font-family: "IBM Plex Sans", sans-serif;
  height: 5vh;
  margin: 0.5em;
  font-size: 1.2em;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #fff;
}
.input-field {
  width: 25vw;
  padding: 1em 2em;
}
.input-field:focus,
.submit-btn:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 6px -1px,
    rgb(0 0 0 / 20%) 0px 2px 4px -2px;
  outline: none;
}
.input-field:focus + .input-span,
.input-field:not(:placeholder-shown) + .input-span {
  opacity: 1;
  transform: scale(0.75) translateY(-110%) translateX(-50px);
  color: rgb(100, 100, 100);
}
.submit-btn {
  width: 10vw;
}

/* Profile */
.profile-main {
  display: flex;
  justify-content: space-between;
  padding: 1em 3em 3em 1em;
  align-items: center;
  font-size: 3em;
  font-weight: 400;
}
.profile-bio {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.5em;
  font-weight: 200;
}
.profile-pic {
  border-radius: 50%;
}
.profile-links {
  display: flex;
  justify-content: space-evenly;
  font-size: 0.8em;
  margin: 0.5em 0;
}
.profile-info {
  display: grid;
  font-size: 0.4em;
  margin: 0 2em;
  grid-template-columns: 40px auto;
  grid-template-rows: auto;
}

/* ********** */
/* Repo Cards */
/* ********** */
.projects-section {
  position: relative;
}
.section-heading {
  text-align: center;
}
.repo-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}
.repo-card {
  font-weight: 500;
  min-width: 40%;
  max-width: 40%;
  border: 2px solid black;
  padding: 20px;
  border-radius: 5px;
}
.repo-name {
  font-size: 1.2rem;
  font-weight: 900;
  margin: 0 10px 0 0;
}
.repo-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.fa-calendar-days {
  color: rgb(194, 194, 194);
}
.more-projects {
  margin: 50px 0;
  margin-bottom: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-arrow-right {
  margin: 0 0 0 5px;
}

/* ----------------------------------- */
/* Followers */
/* ----------------------------------- */
.fc-parent {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  grid-template-columns: auto auto auto auto auto;
}
.fc-card > a {
  color: rgb(123, 123, 123);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.fc-card > a:hover {
  color: #000;
}
.fc-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin: 10px 0;
}

.stats-section {
  text-align: center;
  margin-bottom: 10em;
}

.mul {
  margin: 10px;
  height: 200px;
}

/* ****** */
/* Footer */
/* ****** */
.footer {
  text-align: center;
  margin: 5em 0 1em;
}
